<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <user-bio-panel v-if="userData.name" :user-data="userData" @toggle-user="ToggleUser"></user-bio-panel>
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="userTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items id="user-tabs-content" v-model="userTab" class="mt-5 pa-1">
          <v-tab-item>
            <component
              :is="componentOverview"
              :groupsData="groupsData"
              :userData="userData"
              :isAdmin="isAdmin"
            ></component>
          </v-tab-item>
          <v-tab-item>
            <user-tab-security></user-tab-security>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'

import { onUnmounted, ref, watch } from 'vue'
import StudentTabOverview from './student-tab-overview/StudentTabOverview.vue'
import SupervisorTabOverview from './supervisor-tab-overview/SupervisorTabOverview.vue'
import UserTabSecurity from './user-tab-security/UserTabSecurity.vue'

// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiLockOutline } from '@mdi/js'
import UserBioPanel from './user-bio-panel/UserBioPanel.vue'

export default {
  components: {
    UserBioPanel,
    StudentTabOverview,
    UserTabSecurity,
    SupervisorTabOverview,
  },
  setup() {

    const userData = ref({})
    const groupsData = ref([])
    const userDataStorage = store.getters['user/getUser']
    const userTab = ref(null)
    const role = router.currentRoute.params.role ? router.currentRoute.params.role : userDataStorage.role
    const isAdmin = ref(!!router.currentRoute.params.id)
    const componentOverview = ref('')

    const ToggleUser = () => {
      store
        .dispatch(router.currentRoute.params.id ? 'user/fetchUser' : 'user/fetchProfile', {
          id: router.currentRoute.params.id ? router.currentRoute.params.id : userData.value._id,
        })
        .then(response => {
          userData.value = response.data.user
          groupsData.value = response.data.groups
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = {}
          }
        })
    }

    const loadUser = () => {
      store
        .dispatch(router.currentRoute.params.id ? 'user/fetchUser' : 'user/fetchProfile', {
          id: router.currentRoute.params.id,
        })
        .then(response => {
          userData.value = response.data.user
          groupsData.value = response.data.groups
          if (userData.value.role == 'student') componentOverview.value = 'StudentTabOverview'
          else if (userData.value.role == 'teacher' && response.data.groups.length)
            componentOverview.value = 'StudentTabOverview'
          else if (userData.value.role == 'companySupervisor') componentOverview.value = 'SupervisorTabOverview'
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = {}
          }
        })
    }

    const tabs = [{ icon: mdiAccountOutline, title: 'Vista General' }]

    if (!isAdmin.value) tabs.push({ icon: mdiLockOutline, title: 'Seguridad' })

    loadUser()

    watch(
      () => router.currentRoute.params,
      (toParams, previousParams) => {
        loadUser()
      },
    )

    return {
      tabs,
      userTab,
      userData,
      groupsData,
      role,
      router,
      ToggleUser,
      isAdmin,
      componentOverview,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

tbody {
  tr:hover {
    cursor: pointer !important;
  }
}
</style>
