<template>
  <div class="user-tab-security">
    <!-- change password -->
    <v-card class="mb-7">
      <v-card-title> Cambiar Contraseña </v-card-title>

      <v-card-text>
        <v-alert color="warning" text>
          <p class="font-weight-semibold mb-1">Asegúrese de que se cumplan estos requisitos</p>
          <p class="text-sm mb-0">Mínimo 8 caracteres de largo, mayúsculas y números</p>
        </v-alert>

        <!-- form -->
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="currentPassword"
                outlined
                dense
                label="Contraseña actual"
                :error-messages="errorMessages.currentPassword"
                :rules="[validators.required]"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newPassword"
                outlined
                dense
                label="Nueva contraseña"
                :error-messages="errorMessages.newPassword"
                :rules="[validators.required]"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="confirmPassword"
                outlined
                dense
                label="Confirmación nueva contraseña"
                :error-messages="errorMessages.confirmPassword"
                :rules="[validators.required]"
                :type="isPasswordConfirmVisible ? 'text' : 'password'"
                :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" type="submit"> Guardar </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- dialog -->
    <v-dialog v-model="istwoFactorDialogOpen" max-width="650px">
      <v-card class="two-factor-dialog pa-sm-10">
        <v-card-title class="text-sm-h5 justify-center"> Enable One Time Password </v-card-title>
        <v-card-text>
          <h4 class="text-lg font-weight-medium mb-2">Verify Your Mobile Number for SMS</h4>
          <p>Enter your mobile phone number with country code and we will send you a verification code.</p>

          <v-form>
            <v-text-field
              v-model="smsVerificationNumber"
              outlined
              dense
              placeholder="Mobile number with country code"
              label="Mobile number with country code"
            ></v-text-field>

            <div class="d-flex align-center">
              <v-btn
                color="secondary"
                outlined
                class="me-3 ms-auto"
                @click="istwoFactorDialogOpen = !istwoFactorDialogOpen"
              >
                Cancel
              </v-btn>
              <v-btn color="primary" @click="istwoFactorDialogOpen = !istwoFactorDialogOpen"> send </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import snackbarPlugin from '@/plugins/snackbar'
import store from '@/store'
import { required } from '@core/utils/validation'
import { mdiDeleteOutline, mdiEyeOffOutline, mdiEyeOutline, mdiSquareEditOutline } from '@mdi/js'
import { ref } from 'vue'

export default {
  setup() {
    const istwoFactorDialogOpen = ref(false)
    const currentPassword = ref('')
    const newPassword = ref('')
    const confirmPassword = ref('')
    const smsVerificationNumber = ref('+1(968) 819-2547')
    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)
    const userDataStorage = store.getters['user/getUser']
    const errorMessages = ref([])
    const valid = ref(false)
    const form = ref(null)

    // on form submit
    const onSubmit = () => {
      errorMessages.value = []
      if (valid.value) {
        if (newPassword.value == confirmPassword.value) {
          store
            .dispatch('user/changePassword', {
              _id: userDataStorage._id,
              currentPassword: currentPassword.value,
              newPassword: newPassword.value,
            })
            .then(r => {
              snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
              form.value.reset()
            })
            .catch(e => {
              errorMessages.value = e.response.data.message
            })
        } else {
          errorMessages.value = { confirmPassword: ['Revise que las contraseñas sean iguales.'] }
        }
      } else {
        form.value.validate()
      }
    }

    return {
      istwoFactorDialogOpen,
      newPassword,
      confirmPassword,
      currentPassword,
      smsVerificationNumber,
      isPasswordVisible,
      isPasswordConfirmVisible,
      errorMessages,
      onSubmit,
      valid,
      form,
      validators: {
        required,
      },
      icons: {
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>
